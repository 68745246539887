import React from "react";
import styled from "styled-components";
export {
  red,
  volcano,
  gold,
  orange,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta
} from "@ant-design/colors";

/**
 * Cenareo Company Colors
 * https://drive.google.com/file/d/1aDSZougJU5isSZmRtpycHkvzds0F6GU6/view
 */
export const cenareoCompanyColors = {
  deepPurple: {
    tint30percent: "#B3B3C2",
    tint60percent: "#666684",
    tint70percent: "#4D4D70",
    tint90percent: "#1A1A47",
    tint100percent: "#000032"
  },
  scarlet: {
    tint30percent: "#FFBABA",
    tint60percent: "#FF7575",
    tint70percent: "#FF5E5E",
    tint90percent: "#FF3030",
    tint100percent: "#FF1919"
  },
  azure: {
    tint30percent: "#C9DBFF",
    tint60percent: "#93B7FF",
    tint70percent: "#81ABFF",
    tint90percent: "#5D93FF",
    tint100percent: "#4B87FF"
  },
  aqua: {
    tint30percent: "#B3F9E7",
    tint60percent: "#66F3CF",
    tint70percent: "#4DF1C7",
    tint90percent: "#1AEDB7",
    tint100percent: "#00EBAF"
  },
  amber: {
    tint30percent: "#FFE7B3",
    tint60percent: "#FFCF66",
    tint70percent: "#FFC74D",
    tint90percent: "#FFB71A",
    tint100percent: "#FFAF00"
  },
  black: {
    tint5percent: "#F2F2F2",
    tint10percent: "#E5E5E5",
    tint80percent: "#333333",
    tint90percent: "#1A1A1A"
  }
};

/**
 * Neutral color palette
 * (not in @ant-design/colors)
 * https://www.figma.com/file/bQTRfd7t4GVJZnZuL56Eve/Cenareo-Design-System-Ant-Design?node-id=0%3A2368
 *
 */
export const gray = [
  "#ffffff", // color = gray-1, code = gray[0]
  "#fafafa",
  "#f5f5f5",
  "#f0f0f0",
  "#d9d9d9",
  "#bfbfbf",
  "#8c8c8c",
  "#595959",
  "#434343",
  "#262626",
  "#1f1f1f",
  "#141414",
  "#000000" // color = gray-13, code = gray[12]
];

/******** Cenareo colors from Figma ********/
// https://www.figma.com/file/bQTRfd7t4GVJZnZuL56Eve/Cenareo-Design-System-%2B-Ant-Design?node-id=94%3A2946
export const cenareoColors = {
  blue: {
    primary: "#4B87FF",
    primaryLight: "#93B7FF",
    alt1: "#5D93FF",
    alt2: "#6F9FFF",
    alt3: "#81ABFF"
  },
  green: {
    primary: "#00EBAF",
    alt1: "#1AEDB7",
    alt2: "#33EFBF",
    alt3: "#4DF1C7",
    alt4: "#66F3CF"
  },
  red: {
    primary: "#FF5E5E",
    alt1: "#FF1919",
    alt2: "#FF3030",
    alt3: "#FF4747",
    alt4: "#FF7575"
  },
  dark: {
    primary: "#000032",
    light: "#666684",
    alt1: "#1A1A47",
    alt2: "#33335B",
    alt3: "#4D4D70"
  },
  yellow: {
    primary: "#FFAF00",
    alt1: "#FFCF66",
    alt2: "#FFB71A",
    alt3: "#FFBF33",
    alt4: "#FFC74D"
  }
};

/******** Legacy colors from Marisella design system ********/
/******** DEPRECATED ********/
const colors = {
  gray: "#b0bac9",
  lightgray: "#f6f8f9",
  blue: "#3e85ff",
  primary: "#3e85ff",
  primarylight: "#a3c5ff",
  mediumlightgray: "#EBEEF2",
  mediumgray: "#c5ccd7",
  darkgray: "#777",
  shadow: "#0000001f",
  paleblue: "#edf3ff",
  lightblue: "#d6e4ff",
  dimblue: "#3c89fc78",
  darkviolet: "#020031",
  darkblue: "#114fba",
  secondary: "#020031",
  secondarylight: "#8798ad",
  white: "#fff",
  black: "#000",
  red: "#ff6565",
  lightred: "#ffcccc",
  green: "#00ebaf",
  screenGroupsColor: "#03a9f4"
};
/******** DEPRECATED ********/
const complementaries = {
  gray: ["paleblue", "black", "blue"],
  lightgray: ["blue", "black", "darkgray"],
  blue: ["lightgray", "paleblue", "white"],
  mediumgray: ["blue", "black", "paleblue"],
  mediumlightgray: ["blue", "black", "paleblue"],
  darkgray: ["white", "paleblue", "lightgray"],
  paleblue: ["darkgray", "black", "blue"],
  lightblue: ["darkgray", "black", "blue"],
  dimblue: ["white", "black", "darkgray"],
  darkviolet: ["lightgray", "white", "lightblue"],
  darkblue: ["lightgray", "white", "lightblue"],
  white: ["darkgray", "black", "blue"],
  black: ["lightgray", "white", "lightblue"],
  red: ["white"],
  primary: ["white"],
  primarylight: ["white", "primary"],
  secondary: ["white"],
  secondarylight: ["white", "secondary"],
  green: ["white"]
};

const PaletteUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    flex-direction: row;
  }

  span {
    display: inline-block;
    margin: 1px;
    text-align: center;
    width: 25%;
  }
`;

export const Palette = () => {
  return (
    <PaletteUl>
      {Object.keys(colors).map((colorName) => {
        return (
          <li key={colorName}>
            <span>{colorName}</span>
            {complementaries[colorName] &&
              complementaries[colorName].map((complementaryName) => {
                return (
                  <span
                    style={{
                      color: colors[complementaryName],
                      backgroundColor: colors[colorName]
                    }}
                  >
                    {complementaryName}
                  </span>
                );
              })}
          </li>
        );
      })}
    </PaletteUl>
  );
};

export const color2datauriSVG = (color) => {
  /* In Data URI SVG, the # has to be escaped */
  if (color[0] === "#") {
    return "%23" + color.slice(1);
  }
  return color;
};

export default colors;
