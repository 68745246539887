import React from "react";

import CustomIcon from "@ant-design/icons";

import CenareoLogo from "./CenareoLogo";

export const CenareoLogoIcon = (props: any) => (
  <CustomIcon component={CenareoLogo} {...props} />
);

interface CenareoIconInterface {
  [x: string]: any;
  component: any;
  style?: any;
  disabled?: boolean;
  ant?: boolean;
}

export const CenareoCustomIcon = ({
  component,
  style,
  disabled,
  ...props
}: CenareoIconInterface) => (
  // @ts-ignore
  <CustomIcon
    component={component}
    style={{
      verticalAlign: "-0.20em",
      fontSize: "1.3em",
      opacity: disabled ? "20%" : "inherit",
      ...style
    }}
    {...props}
  />
);

/**
 * use in screens table
 *
 * @param ant If the icon is an Antd/Font-awesome icon
 */
export const CenareoTableIcon = ({
  component,
  style,
  disabled,
  ant,
  ...props
}: CenareoIconInterface) => (
  // @ts-ignore
  <CustomIcon
    component={component}
    style={{
      verticalAlign: "-0.33em",
      fontSize: ant ? "1.5em" : "2em",
      marginRight: "14px",
      opacity: disabled && "20%",
      ...style
    }}
    {...props}
  />
);

export const StyledFaIcon: React.FC<{ faIconName: string }> = ({
  faIconName
}) => (
  <i
    className={`fa fa-${faIconName} fa-lg fa-fw`}
    style={{
      backgroundColor: "white",
      color: "#0088cc",
      height: "48px",
      width: "48px",
      margin: "16px auto 0",
      fontSize: "48px"
    }}
  />
);
